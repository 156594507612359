<template>
  <v-container fluid>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">
      <v-row align="center">
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
          <v-select
            @change="changeReportType"
            v-model="modelReportType"
            :items="reportType"
            label="Report Type"
            prepend-inner-icon="mdi-file-search"
            solo dense hide-details
          ></v-select>
        </v-col>

        <!-- <v-col v-if="modelReportType == 'Exceedance Report' ">
          <v-select
            v-model="valStation"
            prepend-inner-icon="mdi-store-marker"
            :items="itemStation"
            label="Station" solo dense hide-details
          >
          </v-select>
        </v-col> -->

        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'">
          <v-select
            v-model="modelYear"
            @change="selectYear"
            prepend-inner-icon="mdi-calendar"
            :items="dropdownYearDate"
            label="Year"
            solo dense hide-details
          >
          </v-select>
        </v-col>

        <v-col v-if="modelReportType !== 'Yearly Report'">
          <v-select
            v-model="modelMonth"
            @change="getMonthID($event)"
            prepend-inner-icon="mdi-calendar"
            :items="dropdownMonthDate"
            label="Month" solo dense hide-details
          >
          </v-select>
        </v-col>

        

        <v-col cols="12" sm="6" md="4">
          <v-btn
            :disabled="disableBtnGenerate"
            class="button white--text mr-2"
            @click="generateReport"
          >
            GENERATE REPORT
          </v-btn>
        </v-col>
      </v-row>

      <v-card>
        <v-btn
          class="button white--text mr-5 mt-4"
          style="float:right;"
          @click="selectOptionUpload = true"
          :disabled="disableUpload"
        >
          UPLOAD FILE
        </v-btn> <br><br>

        <!-- Mini Title (Table Title) -->
        <!-- tbody_CMSR.length != 0 -->
        <v-row class="ma-0">
          <v-col class="ma-0">
            <h4 class="text-center" v-if="tbody_CMSR.length != 0">
              {{ modelReportType.toUpperCase() }}
            </h4>
          </v-col>
        </v-row>

        <v-data-table
          :headers="thead_CMSR"
          :items="tbody_CMSR"
          :header-props="{ sortIcon: null }"
          class="elevation-1 tableCMSReporting headerDtSarawak px-4"
          :items-per-page="10"
          no-select-on-click
          fixed-header
          :loading="this.loadingTable"
          :loading-text="this.loadingText"
          :custom-sort="customSort"
        >
          <template v-slot:[`item.report`]="{ item }">
            <v-icon
              dense
              color="primary"
              v-html="item.report"
              @click="openFileReport(item)"
            >
            </v-icon>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <v-icon
              dense
              color="green"
              v-html="item.edit"
              @click="editReport(item)"
            >
            </v-icon>
          </template>

          <template v-slot:[`item.delete`]="{ item }">
            <v-icon
              dense
              color="red"
              v-html="item.delete"
              @click="deleteReport(item)"
            >
            </v-icon>
          </template>
        </v-data-table>

        <v-file-input
          class="d-none"
          label="File input"
          id="fileEdit"
          ref="fileEdit"
          v-on:change="handleEditInputFile($event)"
        ></v-file-input>
      </v-card>

    </v-container>

    

    <!-- Popup Upload File -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="selectOptionUpload" persistent max-width="600px">
          <v-card>
            
            <v-card-title class="card-header white--text">
              <v-icon class="white--text mr-2">mdi-file-upload</v-icon><h3>Upload File</h3>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-select
                      @change="selectTypeUpload($event)"
                      v-model="modelUploadType"
                      :items="uploadType"
                      label="Upload Type" 
                      prepend-inner-icon="mdi-file-upload-outline"
                      outlined dense hide-details
                    ></v-select>
                  </v-col>

                  <!-- <v-col v-if="modelUploadType == 'Exceedance Report' ">
                    <v-select
                      @change="selectTypeUpload()"
                      v-model="modelUploadStn"
                      :items="itemStn"
                      label="Station" 
                      prepend-inner-icon="mdi-store-marker"
                      outlined dense hide-details
                    ></v-select>
                  </v-col>
                  <v-col v-else> -->
                  <v-col>
                    <v-select
                      @change="selectSubTypeUpload()"
                      v-model="modelUploadTypeYear"
                      prepend-inner-icon="mdi-calendar"
                      :items="dropdownYearDate"
                      label="Year"
                      outlined dense hide-details
                    >
                    </v-select>
                  </v-col>

                  <v-col v-if="modelUploadType !== 'Yearly Report'">
                    <v-select
                      @change="selectSubTypeUpload()"
                      v-model="modelUploadTypeMonth"
                      prepend-inner-icon="mdi-calendar"
                      :items="dropdownUploadMonth"
                      label="Month"
                      outlined dense hide-details
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <!-- <v-row  v-if="modelUploadType == 'Exceedance Report' ">

                  <v-col>
                    <v-select
                      @change="selectSubTypeUpload()"
                      v-model="modelUploadTypeYear"
                      prepend-inner-icon="mdi-calendar"
                      :items="dropdownYearDate"
                      label="Year"
                      outlined dense hide-details
                    >
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-select
                      @change="selectSubTypeUpload()"
                      v-model="modelUploadTypeMonth"
                      prepend-inner-icon="mdi-calendar"
                      :items="dropdownUploadMonth"
                      label="Month"
                      outlined dense hide-details
                    >
                    </v-select>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col>
                      <v-textarea
                      outlined dense hide-details
                      v-model="modelUploadTypeDesc"
                      label="Descriptions"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-file-input
                      :disabled="disabledInputUpload"
                      label="File input"
                      show-size
                      truncate-length="15"
                      id="file"
                      ref="file"
                      v-on:change="handleFileUploadCMSR($event)"
                      outlined dense hide-details
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
              <!-- <small>*indicates required field</small> -->
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="card-footer">
              <v-spacer></v-spacer>
              <v-btn color="error white--text" @click="closeSelectOptionUpload">
                CANCEL
              </v-btn>
              <v-btn
                color="button white--text"
                @click="uploadFileSelection"
                :disabled="disableBtnUploadFile"
              >
                UPLOAD
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>

    <!-- Popups/Dialogs Success -->
    <div class="text-center">
      <v-dialog v-model="dialogSuccess" width="400" persistent>
        <v-card>
          <v-card-title class="text-h5 success white--text">
            Success
            <v-icon style="color:white;margin-left:15px;"
              >mdi-check-circle</v-icon
            >
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            {{ textDialogSuccess }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogSuccess = false">
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Popups/Dialogs Confirmation Edit -->
    <div class="text-center">
      <v-dialog v-model="dialogConfirmationEdit" width="400" persistent>
        <v-card>
          <v-card-title class="text-h5 primary white--text">
            Edit Report
            <v-icon style="color:white;margin-left:15px;"
              >mdi-file-upload</v-icon
            >
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            Are you sure want to edit this report?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogConfirmationEdit = false">
              NO
            </v-btn>
            <v-btn color="primary" text @click="YesEditIt">
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Popups/Dialogs Confirmation Delete -->
    <div class="text-center">
      <v-dialog v-model="dialogConfirmationDelete" width="400" persistent>
        <v-card>
          <v-card-title class="text-h5 error white--text">
            Delete
            <v-icon style="color:white;margin-left:15px;">mdi-delete</v-icon>
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            Are you sure want to delete?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialogConfirmationDelete = false"
            >
              NO
            </v-btn>
            <v-btn color="error" text @click="YesDeleteIt">
              YES
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Popups/Dialogs Error -->
    <div class="text-center">
      <v-dialog v-model="dialogError" width="500">
        <v-card>
          <v-card-title class="text-h5 error white--text">
            Error!
            <v-icon style="color:white;margin-left:15px;"
              >mdi-alert-circle</v-icon
            >
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            Something when wrong. Please try again.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color:red !important;"
              color="error"
              text
              @click="dialogError = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
// import Papa from "papaparse";

export default {
  data: () => ({
    // General
    dialogError: false,
    dialogSuccess: false,
    textDialogSuccess: "",
    disableUpload: true,
    // Path
    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/",
        // href: '/Mapvsadiewer',
      },
      {
        text: "CMS Reporting - Reporting",
        disabled: true,
        href: "/CMSReporting/Reporting",
      },
    ],
    valStation: null,

    // Filtering
    reportType: ["Yearly Report", "Monthly Report", "Maintenance Schedule", "Exceedance Report"],
    dropdownYearDate: [],
    dropdownMonthDate: [
      "All Month",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    dropdownUploadMonth: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    listMonthDate: [
      { id: "all", text: "All Month" },
      { id: "01", text: "January" },
      { id: "02", text: "February" },
      { id: "03", text: "March" },
      { id: "04", text: "April" },
      { id: "05", text: "May" },
      { id: "06", text: "June" },
      { id: "07", text: "July" },
      { id: "08", text: "August" },
      { id: "09", text: "September" },
      { id: "10", text: "October" },
      { id: "11", text: "November" },
      { id: "12", text: "December" },
    ],
    disableBtnGenerate: true,

    // Model
    modelReportType: null,
    modelYear: null,
    modelMonth: null,
    modelMonthID: null,
    modelUploadStn: null,
    modelUploadTypeDesc: null,

    // DataTable
    loadingTable: false,
    loadingText: "Data Loading... Please wait",
    thead_CMSR: [],
    tbody_CMSR: [],
    dataForEdited: null,
    dialogConfirmationEdit: false,
    pathEditFile: "",
    dataForDeleted: null,
    dialogConfirmationDelete: false,
    itemStation: ['All Station'],
    itemStn: [],

    // Upload File
    selectOptionUpload: false,
    uploadType: ["Yearly Report", "Monthly Report", "Maintenance Schedule", "Exceedance Report"],
    modelUploadType: "Yearly Report",
    modelUploadTypeYear: null,
    modelUploadTypeMonth: null,
    file: "",
    pathFile: "",
    disabledInputUpload: true,
    disableBtnUploadFile: true,
  }),
  methods: {
    // GET (General)

     convert12Hours(time) {
      var H = +time.substr(0, 2);
      var h = H % 12 || 12;
      var ampm = H < 12 || H === 24 ? "AM" : "PM";
      time = h + time.substr(2, 3) + time.substr(5, 3) + " " + ampm;
      return time;
    },

    convertDateFormat(date) {
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    load() {
      this.modelReportType = "Yearly Report";
    },

    getListStation() {

    this.sta_loc = [];

    axios.get(this.globalUrl + 'bakaj/stations', {
        headers: {
            'Authorization': 'Bearer ' + this.$store.getters.user.token,
        }
    })
        .then((response) => {
            // this.itemStation = [];

            for (let i = 0; i < response.data.length; i++) {
                this.itemStation.push(response.data[i].stationId + " - " + response.data[i].location);
                this.itemStn.push(response.data[i].stationId + " - " + response.data[i].location);

                let objloc = {
                sid: response.data[i].stationId,
                loc: response.data[i].location
            }

            this.sta_loc.push(objloc);
            }

            // console.log(this.sta_loc)

          

        })
        .catch(error => {
            console.log(error);
        })
        
    },

    getApiDateYear() {
      axios
        .get(this.globalUrl+"bakaj/years", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // this.stationDetails = response.data;
          // console.log(response.data);
          for (let i = 0; i < response.data.length; i++) {
            this.dropdownYearDate.push(response.data[i]);
          }

          // console.log(this.itemStation);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectYear() {
      if (this.modelReportType == "Yearly Report") {
        if (this.modelYear != null) {
          this.disableBtnGenerate = false;
        } else {
          this.disableBtnGenerate = true;
        }
      } else {
      // } else if (this.modelReportType == "Monthly Report") {
        if (this.modelMonth != null && this.modelYear != null) {
          this.disableBtnGenerate = false;
        } else {
          this.disableBtnGenerate = true;
        }
      }
    },

    getMonthID(month) {
      for (let i in this.listMonthDate) {
        if (this.listMonthDate[i].text == month) {
          this.modelMonthID = this.listMonthDate[i].id;
        }
      }

      if (this.modelMonth != null && this.modelYear != null) {
        this.disableBtnGenerate = false;
      } else {
        this.disableBtnGenerate = true;
      }
    },

    changeReportType() {
      this.modelYear = null;
      this.modelMonth = null;
      this.modelMonthID = null;
      this.disableBtnGenerate = true;
    },

    // Generate Datatable

    generateReport() {
      this.loadingTable = true;
      this.disableUpload = false;
      this.thead_CMSR = [];
      this.tbody_CMSR = [];

      // console.log(this.valStation)

      // let cur_sta = this.valStation.split(" - ")[0]

      let url_link = ""

      // this.valStation == null ? url_link = this.globalUrl+"maint/docs?year=" + this.modelYear + "&month=" + this.modelMonthID + "&type=" + this.modelReportType.replace(' Report','').replace(' Schedule','').toLowerCase() : url_link = this.globalUrl+"maint/docs?year=" + this.modelYear + "&month=" + this.modelMonthID + "&type=exceedance&station_id="+cur_sta

      axios
        .get(
          this.globalUrl+"maint/docs?year=" + this.modelYear + "&month=" + this.modelMonthID + "&type=" + this.modelReportType.replace(' Report','').replace(' Schedule','').toLowerCase(),
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {

          // console.log(this.modelReportType)

          if(this.modelReportType == 'Exceedance Report'){
            this.thead_CMSR.push(
            // { text: "REPORT NAME", value: "reportname", sortable: true },
            { text: "UPLOAD BY", value: "updateby", sortable: true },
            { text: "UPDATE DATETIME", value: "updatedatetime", sortable: true },
            { text: "DESCRIPTION", value: "description", sortable: true },
            { text: "REPORT", value: "report", sortable: false },
            { text: "EDIT", value: "edit", sortable: false },
            { text: "DELETE", value: "delete", sortable: false },
           );
          } else {
            this.thead_CMSR.push(
            { text: "REPORT NAME", value: "reportname", sortable: true },
            { text: "UPDATE DATETIME", value: "updatedatetime", sortable: true },
            { text: "UPLOAD BY", value: "updateby", sortable: true },
            { text: "REPORT", value: "report", sortable: false },
            { text: "EDIT", value: "edit", sortable: false },
            { text: "DELETE", value: "delete", sortable: false },
            );
          }
          

          let data = response.data;
          console.log(data);

          data.forEach(function(res) {
            res["report"] = "mdi-file-document-outline";
            res["edit"] = "mdi-pencil";
            res["delete"] = "mdi-delete";
          });

          for (let i in data){
            if (data[i]["uploaddatetime"].includes("T")) {
              data[i]["uploaddatetime"] = this.convertDateFormat(data[i]["uploaddatetime"].split("T")[0]) + " " +  this.convert12Hours(data[i]["uploaddatetime"].split("T")[1]);
            }

            if (data[i]["updatedatetime"].includes("T")) {
              data[i]["updatedatetime"] = this.convertDateFormat(data[i]["updatedatetime"].split("T")[0]) + " " +  this.convert12Hours(data[i]["updatedatetime"].split("T")[1]);
            }
          }

          this.tbody_CMSR = data;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },

    openFileReport(data) {
      console.log(data);
      window.open(data.filepath, "_blank");
    },

    editReport(data) {
      console.log("edit file");

      this.dataForEdited = data;

      let fileUpload = document.getElementById("fileEdit");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },

    handleEditInputFile(data) {
      // console.log(data);

      let formData = new FormData();

      formData.append("files", data);

      axios
        .post(this.globalUrl+`bakaj/upload`, formData, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          console.log(response);
          // this.stationData = response.data.filepath
          this.pathEditFile = response.data.filepath;
        })
        .catch((error) => {
          console.log(error);
        });

      let myInterval = setInterval(() => {
        if (this.pathEditFile != null) {
          this.dialogConfirmationEdit = true;
        } else {
          this.dialogError = true;
        }
        clearInterval(myInterval);
      }, 1000);
    },

    YesEditIt() {
      console.log(this.pathEditFile);


      axios
        .get(
          this.globalUrl+"maint/docedit?id=" + this.dataForEdited.id + "&fileurl=" + this.pathEditFile,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.dialogConfirmationEdit = false;
          this.generateReport();
        })
        .catch((error) => {
          console.log(error);
          this.dialogConfirmationEdit = false;
          this.dialogError = true;
        });
    },

    deleteReport(data) {
      this.dialogConfirmationDelete = true;
      this.dataForDeleted = data;
    },

    YesDeleteIt() {
      axios
        .get(
          this.globalUrl+"maint/docdelete?id=" +
            this.dataForDeleted.id,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.generateReport();
          this.dialogConfirmationDelete = false;
        })
        .catch((error) => {
          console.log(error);

          this.dialogConfirmationDelete = false;
          this.dialogError = true;
        });
    },

    // Upload File

    selectTypeUpload(type) {
      this.modelUploadTypeYear = null;
      this.modelUploadTypeMonth = null;
      this.disabledInputUpload = true;
      this.disableBtnUploadFile = true;
    },

    selectSubTypeUpload() {

      console.log(this.modelUploadType)
      if (this.modelUploadType == "Yearly Report") {
        if (this.modelUploadTypeYear != null) {
          this.disabledInputUpload = false;
          if (this.pathFile != "") {
            this.disableBtnUploadFile = false;
          } else {
            this.disableBtnUploadFile = true;
          }
        } else {
          this.disabledInputUpload = true;
          this.disableBtnUploadFile = true;
        }
      // } else if (this.modelUploadType == "Monthly") {
      } else {
        if (this.modelUploadTypeYear != null) {
          if (this.modelUploadTypeMonth != null) {
            this.disabledInputUpload = false;
            if (this.pathFile != "") {
              this.disableBtnUploadFile = false;
            } else {
              this.disableBtnUploadFile = true;
            }
          } else {
            this.disabledInputUpload = true;
            this.disableBtnUploadFile = true;
          }
        } else {
          this.disabledInputUpload = true;
          this.disableBtnUploadFile = true;
        }
      }
    },

    handleFileUploadCMSR(e) {
      console.log(e);
      // console.log(this.$refs);
      // this.file = this.$refs.file.files[0];
      this.file = e;

      let formData = new FormData();

      formData.append("files", e);

      axios
        .post(this.globalUrl+`bakaj/upload`, formData, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {

          console.log("sini")
          console.log(response);
          // this.stationData = response.data.filepath
          this.pathFile = response.data.filepath;
          this.disableBtnUploadFile = false;
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status == 400) {
            this.pathFile = "";
            this.disableBtnUploadFile = true;
          }
        });
    },

    closeSelectOptionUpload() {
      this.selectOptionUpload = false;

      this.modelUploadType = "Yearly Report";
      this.modelUploadTypeYear = null;
      this.modelUploadTypeMonth = null;
      this.file = "";
      this.pathFile = "";
      this.$refs.file.reset();
      this.disabledInputUpload = true;
      this.disableBtnUploadFile = true;
    },

    uploadFileSelection() {

      let up_type = this.modelUploadType.replace(' Report','').replace(' Schedule','').toLowerCase()

      console.log(this.modelUploadTypeDesc)
      let payload = {
        year: this.modelUploadTypeYear,
        month: this.modelUploadTypeMonth,
        fileurl: this.pathFile,
        type: up_type,
        description: this.modelUploadTypeDesc
      };

      

      for (let i in this.listMonthDate) {
        if (this.listMonthDate[i].text == this.modelUploadTypeMonth) {
          payload.month = this.listMonthDate[i].id;
        }
      }

      // console.log(payload)

      axios
        .post(
          this.globalUrl+"maint/docadd",
          payload,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.selectOptionUpload = false;

          this.textDialogSuccess = "Your upload file is successful";
          this.dialogSuccess = true;
        })
        .catch((error) => {
          console.log(error);
          this.selectOptionUpload = false;
          this.dialogError = true;
        });
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

    //   console.log(index);

      items.sort((a, b) => {
        if (index[0] == "uploaddatetime" || index[0] == "updatedatetime") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },
  mounted() {
    this.load();
    this.getApiDateYear();
    this.getListStation();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

/* Section Path File/Modul */
.pathModul {
  padding-bottom: 0px;
}

.pathModul > li:nth-child(3) > a,
.pathModul > li:nth-child(3) > div {
  color: black !important;
}
</style>